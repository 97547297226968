import { useEffect } from 'react';

import { getMemberId } from '@trello/authentication';
import { PersistentSharedState, useSharedState } from '@trello/shared-state';

import type { MemberEnterpriseDataFragment } from './MemberEnterprisesDataFragment.generated';
import { useMemberEnterpriseDataFragment } from './MemberEnterprisesDataFragment.generated';

export interface MemberEnterpriseDataSharedState {
  idEnterprise: string | undefined;
  idEnterprises: string[];
  inEnterprise: boolean | undefined;
}

const enterpriseDataSharedState: MemberEnterpriseDataSharedState = {
  idEnterprise: undefined,
  idEnterprises: [],
  inEnterprise: undefined,
};

const makeEnterpriseData = (
  memberData: MemberEnterpriseDataFragment | undefined,
): MemberEnterpriseDataSharedState => {
  return {
    idEnterprise: memberData?.idEnterprise || undefined,
    idEnterprises: memberData?.enterprises?.map(({ id }) => id) || [],
    inEnterprise: (memberData?.enterprises || []).length > 0,
  };
};

const memberIdEnterprisesSharedState =
  new PersistentSharedState<MemberEnterpriseDataSharedState>(
    enterpriseDataSharedState,
    {
      storageKey: () => `memberEnterpriseData-${getMemberId() || 'anonymous'}`,
    },
  );

export const useCachedMemberEnterpriseData: (
  trelloMemberId: string,
) => MemberEnterpriseDataSharedState = (trelloMemberId) => {
  const { data: memberData, complete: isMemberDataLoaded } =
    useMemberEnterpriseDataFragment({
      from: { id: trelloMemberId },
    });

  const [enterprisesState, setMemberEnterpriseData] = useSharedState(
    memberIdEnterprisesSharedState,
  );

  useEffect(() => {
    if (!isMemberDataLoaded) {
      return;
    }

    setMemberEnterpriseData(makeEnterpriseData(memberData));
  }, [isMemberDataLoaded, memberData, setMemberEnterpriseData]);

  if (!isMemberDataLoaded) {
    return enterprisesState;
  }

  return makeEnterpriseData(memberData);
};
