import type {
  FunctionComponent,
  KeyboardEventHandler,
  PropsWithChildren,
} from 'react';
import { useCallback, useEffect } from 'react';
import classNames from 'classnames';

import type { ActionSubjectIdType } from '@trello/analytics-types';
import { Analytics } from '@trello/atlassian-analytics';
import { DynamicButton } from '@trello/dynamic-tokens';
import { Key } from '@trello/keybindings';
import { DownIcon } from '@trello/nachos/icons/down';
import { Popover, usePopover } from '@trello/nachos/popover';

import styles from './HeaderMenu.less';

interface HeaderMenuProps {
  buttonText: string;
  analyticsButtonName: ActionSubjectIdType;
  analyticsComponentName: ActionSubjectIdType;
  ariaPopoverTitle: string;
  dataTestId?: string;
  // when shouldHidePopover is true, invoke Nachos Popover's `hide` function
  shouldHidePopover?: boolean;
  // when popover visibility changes from visible to not visible,
  // `resetShouldHidePopover` is invoked if defined
  resetShouldHidePopover?: () => void;
  noHorizontalPadding?: boolean;
  noVerticalPadding?: boolean;
  pushScreen?: { screen: number | undefined; name: string };
  onClick?: () => void;
  isFocusTrappingDisabledForCffe?: boolean;
}

export const HeaderMenu: FunctionComponent<
  PropsWithChildren<HeaderMenuProps>
> = ({
  buttonText,
  analyticsButtonName,
  analyticsComponentName,
  ariaPopoverTitle,
  dataTestId,
  shouldHidePopover,
  resetShouldHidePopover,
  children,
  noHorizontalPadding,
  noVerticalPadding,
  pushScreen,
  onClick,
  isFocusTrappingDisabledForCffe,
}) => {
  const { popoverProps, triggerRef, toggle, hide, push, pop } =
    usePopover<HTMLButtonElement>();

  const { isVisible: popOverIsVisible } = popoverProps;

  const handleClick = useCallback(() => {
    onClick?.();
    toggle();
    Analytics.sendClickedButtonEvent({
      buttonName: analyticsButtonName,
      source: 'appHeader',
    });
  }, [onClick, toggle, analyticsButtonName]);

  // https://www.w3.org/TR/2017/WD-wai-aria-practices-1.1-20170628/examples/menu-button/menu-button-links.html
  // menu buttons should open the menu when the up / down arrow key is used
  const handleKeyDown: KeyboardEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      if (e.key === Key.ArrowDown || e.key === Key.ArrowUp) {
        toggle();
      }
    },
    [toggle],
  );

  const handleHide = useCallback(() => {
    hide();
    Analytics.sendClosedComponentEvent({
      componentType: 'inlineDialog',
      componentName: analyticsComponentName,
      source: 'appHeader',
    });
  }, [hide, analyticsComponentName]);

  const handleBack = useCallback(() => {
    if (popoverProps.onBack) {
      popoverProps.onBack();
    } else {
      pop();
    }
  }, [popoverProps, pop]);

  useEffect(() => {
    if (shouldHidePopover) {
      hide();
    }
  }, [shouldHidePopover, hide]);

  useEffect(() => {
    if (!popOverIsVisible) {
      resetShouldHidePopover?.();
    }
  }, [popOverIsVisible, resetShouldHidePopover]);

  useEffect(() => {
    if (pushScreen?.screen) {
      push(pushScreen.screen);
    }
  }, [push, pushScreen]);

  return (
    <>
      <DynamicButton
        aria-haspopup="true"
        aria-expanded={popOverIsVisible}
        className={classNames(styles.button, {
          [styles.buttonOpen]: popOverIsVisible,
        })}
        data-testid={dataTestId}
        title={buttonText}
        // set aria label so screen reader doesn't read out the down icon
        aria-label={ariaPopoverTitle}
        onClick={handleClick}
        ref={triggerRef}
        iconAfter={
          <DownIcon size="small" dangerous_className={styles.buttonIcon} />
        }
        onKeyDown={handleKeyDown}
      >
        <span className={styles.buttonText}>{buttonText}</span>
      </DynamicButton>
      <Popover
        {...popoverProps}
        onHide={handleHide}
        onBack={handleBack}
        noHorizontalPadding={noHorizontalPadding}
        noVerticalPadding={noVerticalPadding}
        enableArrowKeyNavigation={
          // The Templates menu is a multi-screen popover & screens after
          // the initial one are not "menus". Only enable arrow key
          // navigation on the first popover which is a menu.
          !isFocusTrappingDisabledForCffe && popoverProps.currentScreen === 0
            ? true
            : false
        }
        dangerous_disableFocusTrapping={isFocusTrappingDisabledForCffe}
      >
        {children}
      </Popover>
    </>
  );
};
