import type { OpaqueString } from '@trello/utility-types';

/**
 * @remarks
 * Possible data types to use with the sanitization template
 *
 * ### Primary types
 * | Type    | Description |
 * | -------- | ------- |
 * | `applicationId` | Matches any application ID string, inherits from `otherId`. |
 * | `attachmentId` | Matches any attachment ID string, inherits from `otherId`. |
 * | `boardId` | Matches any board ID string, inherits from `otherId`. |
 * | `butlerButtonId` | Butler button IDs are in the format of `{memberId that created the button}-{epoch at time of button creation}`. |
 * | `cardId` | Matches any card ID string, inherits from `otherId`. |
 * | `memberId` | Matches any user ID, inherits from `otherId`, also matches AAIDs, or an ARI user resource. |
 * | `pluginId` | Matches any plugin ID string, inherits from `otherId`. |
 * | `workspaceId` | Matches any user ID, inherits from `otherId`, also matches AAIDs, or an ARI user resource. |
 *
 * ### Secondary types
 * Don't use these unless nothing in the table above fits your use case.
 * | Type    | Description |
 * | -------- | ------- |
 * | `otherId` | Matches any hex value longer than 6 characters long. |
 * | `stringUnion` | Matches any string specified in the additional property `allowedValues`. Can be matched case-insensitively if `isCaseSensitive` is not provided. |
 */
export type DataTypes =
  | 'actionId'
  | 'applicationId'
  | 'attachmentId'
  | 'boardId'
  | 'boardPref'
  | 'butlerButtonId'
  | 'cardId'
  | 'listId'
  | 'memberId'
  | 'organizationId'
  | 'otherId'
  | 'pluginId'
  | 'reactionId'
  | 'stringUnion'
  | 'username'
  | 'workspaceId';

/**
 * A parameter that is passed to `sanitizeUnsafeString` that indicates that the value should be validated with the
 * appropriate validator from `dataTypeValidators`.
 */
export type TypedUnsafeData = {
  value: number | string;
  type: Exclude<DataTypes, 'stringUnion'>;
};

/**
 * A parameter that is passed to `sanitizeUnsafeString` that indicates that the value should be validated to match one of the
 * allowed values array.
 */
export interface StringUnionUnsafeData extends Omit<TypedUnsafeData, 'type'> {
  value: number | string;
  type: 'stringUnion';
  allowedValues: string[];
  isCaseSensitive?: boolean;
}

export const isStringUnionUnsafeData = (
  data: UnsafeData,
): data is StringUnionUnsafeData => {
  return data.type === 'stringUnion';
};

export type UnsafeData = StringUnionUnsafeData | TypedUnsafeData;

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface SafeUrl extends OpaqueString<SafeUrl, 'SafeUrl'> {}
