import { useMemberId } from '@trello/authentication';
import { atlassianTeams } from '@trello/config';
import { developerConsoleState } from '@trello/developer-console-state';
import { useDynamicConfig } from '@trello/dynamic-config';

import { useShouldShowInternalToolsFragment } from './ShouldShowInternalToolsFragment.generated';

/**
 * Show internal tools if the user is allowed to view multiple channels,
 * or if we're not in a production environment (to make development easier).
 */
export const useShouldShowInternalTools = (
  hostname = window.location.hostname,
): boolean => {
  const memberId = useMemberId();
  const { data } = useShouldShowInternalToolsFragment({
    from: { id: memberId },
  });

  const isOnAtlassianTeam = useDynamicConfig<boolean>(
    'trello_web_atlassian_team',
  );

  const shouldShow =
    isOnAtlassianTeam ||
    hostname !== 'trello.com' ||
    !!(
      data?.email?.endsWith('@atlassian.com') &&
      data?.idOrganizations?.some((idOrg) => atlassianTeams.includes(idOrg))
    );

  // Set the developer console state values if they're not already set
  // This is to ensure that we don't override any user-set values
  // but Atlassians will get the features enabled by default
  if (developerConsoleState.value.logConnectionInformation === undefined) {
    developerConsoleState.setValue({
      logConnectionInformation: shouldShow,
    });
  }
  if (developerConsoleState.value.operationNameInUrl === undefined) {
    developerConsoleState.setValue({
      operationNameInUrl: shouldShow,
    });
  }

  return shouldShow;
};
