import BluebirdPromise from 'bluebird';
import _ from 'underscore';

import { isMemberLoggedIn, logoutUser } from '@trello/authentication';
import Backbone from '@trello/backbone';
import { unmountComponentAtNode } from '@trello/component-wrapper';
import { client, clientVersion } from '@trello/config';
import { dynamicConfigClient } from '@trello/dynamic-config';
import type { PremiumFeature } from '@trello/entitlements';
import { ApiError } from '@trello/error-handling';
import { FavIcon } from '@trello/favicon';
import { getFeatureGateAsync } from '@trello/feature-gate-client';
// eslint-disable-next-line no-restricted-imports
import $ from '@trello/jquery';
import { overlayState } from '@trello/nachos/overlay';
import type { PIIString } from '@trello/privacy';
import {
  convertToPIIString,
  dangerouslyConvertPrivacyString,
} from '@trello/privacy';
import { cacheFactory, QuickLoad } from '@trello/quickload';
import ReactDOM from '@trello/react-dom-wrapper';
import { realtimeUpdaterEvents } from '@trello/realtime-updater';
import { pushRecentBoard, removeRecentBoardById } from '@trello/recent-boards';
import {
  getLocation,
  getRouteIdFromPathname,
  RouteId,
  routes,
} from '@trello/router';
import { navigate } from '@trello/router/navigate';
import { TrelloStorage } from '@trello/storage';
import {
  getMemberAccountUrl,
  getMemberActivityUrl,
  getMemberBoardsUrl,
  getMemberCardsUrl,
  getMemberLabsUrl,
  getOrganizationAccountUrl,
  getOrganizationBillingUrl,
  getOrganizationExportUrl,
  getOrganizationFreeTrialUrl,
  getOrganizationGuestUrl,
  getOrganizationHomeUrl,
  getOrganizationMembersUrl,
  getOrganizationPowerUpsUrl,
  getOrganizationReportsUrl,
  getOrganizationRequestUrl,
  getOrganizationUrl,
  getTeamOnboardingUrl,
  getWorkspaceCustomTableViewUrl,
  getWorkspaceDefaultCustomCalendarViewUrl,
} from '@trello/urls';
import { importWithRetry } from '@trello/use-lazy-component';

import { errorPage } from 'app/scripts/controller/errorPage';
import { getHomeLastTabStorageKey } from 'app/scripts/controller/memberPageHelpers';
import { Auth } from 'app/scripts/db/Auth';
import { ModelLoader } from 'app/scripts/db/model-loader';
import { Util } from 'app/scripts/lib/util';
import { BOARD_VIEW_BACKGROUND_CLASSES } from 'app/scripts/views/board/boardViewBackgroundClasses';
import { View } from 'app/scripts/views/internal/View';
import { preloadCurrentBoardViewAssets } from 'app/src/components/Board/preloadCurrentBoardViewAssets';
import { boardsMenuState } from 'app/src/components/CreateBoard';
import type { ErrorProps } from 'app/src/components/Error/Error.types';
import { cachedInboxIdsState } from 'app/src/components/Inbox/cachedInboxIdsState';
import { controllerEvents } from './controllerEvents';
import { currentModelManager } from './currentModelManager';
// Require a bunch of random methods that will be used to extend the Controller
// prototype. The long-term vision is to get rid of these methods from the Controller.
import { fatalErrorPage } from './fatalErrorPage';
import {
  isShowingBoardViewSection,
  showingAutomaticReports,
  showingCalendar,
  showingMap,
  showingPupDirectory,
} from './getCurrentBoardView';
import { renderPage } from './renderPage';
// these will be removed in a near future PR, added here to reduce blast radius
import { getMemberOrgUrl, getOrganizationMemberCardsUrl } from './urls';

const loadHeaderDataAndTriggerWaits = function (
  controller: Controller,
  methodName: string,
  eventName: string,
) {
  const actualLoad = Auth.isLoggedIn()
    ? // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      ModelLoader[methodName]()
    : BluebirdPromise.resolve(null);

  return actualLoad
    .then(function () {
      return ModelLoader.triggerWaits(eventName);
    })
    .catch(ApiError, (error: typeof ApiError) => {
      if (error instanceof ApiError.Unauthenticated) {
        logoutUser();
        return;
      }

      controller.showFatalErrorPage({
        errorType: 'serverError',
        error:
          error ??
          new Error('Unknown error loading header data for application'),
      });
    })
    .done();
};

interface Controller {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  applicationView: any;

  location: string;

  currentPage: 'search';

  isFirstTimeViewingBoard: boolean;

  unmountReactRoot?: (() => void) | null;
}

class Controller extends Backbone.Router {
  get routes() {
    return {
      /**
       * Routes here are evaluated top to bottom.
       * If you add a route and the client is not rendering the right page,
       * your URL might be getting caught by another route pattern.
       *
       * e.g. the /my-new-route handler needs to be inserted before
       * routes.userOrOrg.pattern otherwise it will be caught there.
       *
       * If you are adding a new route that should be indexed,
       * make sure to add it to `packages/bifrost-template/src/config/indexedPaths.ts`.
       */

      [routes.go.pattern]: 'quickBoard',
      [routes.to.pattern]: 'quickBoard',
      [routes.doubleSlash.pattern]: 'quickBoard',
      [routes.powerUpAdmin.pattern]: 'powerUpAdmin',
      [routes.powerUpEdit.pattern]: 'editPowerUpPage',
      [routes.powerUpPublicDirectory.pattern]: 'publicDirectory',
      [routes.createFirstTeam.pattern]: 'createFirstTeamPage',
      [routes.createWorkspace.pattern]: 'createWorkspacePage',
      [routes.shortcuts.pattern]: 'shortcutsPage',
      [routes.shortcutsOverlay.pattern]: 'shortcutsOverlayPage',
      [routes.blank.pattern]: 'blankPage',
      [routes.selectOrgToUpgrade.pattern]: 'selectOrgToUpgradePage',
      [routes.selectTeamToUpgrade.pattern]: 'selectTeamToUpgradePage',
      [routes.search.pattern]: 'searchPage',
      [routes.openSourceAttributionsPage.pattern]: 'openSourceAttributionsPage',
      [routes.templates.pattern]: 'templatesGalleryPublicPage',
      [routes.templatesRecommend.pattern]: 'templatesGalleryPublicPage',
      [routes.inviteAcceptBoard.pattern]: 'inviteAcceptBoardPage',
      [routes.inviteAcceptTeam.pattern]: 'inviteAcceptTeamPage',
      // Old style URL
      [routes.boardOld.pattern]: 'boardPage',
      // New style URL
      [routes.board.pattern]: 'boardPage',
      // Old style URLs
      [routes.cardAndBoardOld.pattern]: 'cardPage',
      [routes.cardOld.pattern]: 'cardPage',
      // New style URL
      [routes.card.pattern]: 'cardPage',
      [routes.createFirstBoard.pattern]: 'createFirstBoardPage',

      // Redeem route
      [routes.redeem.pattern]: 'redeemPage',

      // User or Org routes
      [routes.account.pattern]: 'userOrOrgAccountPage',
      [routes.oldAccount.pattern]: 'userOrOrgAccountPage',
      [routes.profile.pattern]: 'userOrOrgProfilePage',
      [routes.workspaceBilling.pattern]: 'organizationBillingView',
      [routes.billing.pattern]: 'userOrOrgBillingPage',
      // If in doubt, place your new route before routes.userOrOrg.pattern
      [routes.userOrOrg.pattern]: 'userOrOrgProfilePage',
      [routes.enterpriseAdmin.pattern]: 'enterpriseAdminDashboardView',
      [routes.enterpriseAdminTab.pattern]: 'enterpriseDashTab',
      [routes.memberHome.pattern]: 'memberHomePage',
      [routes.memberHomeBoards.pattern]: 'memberHomeBoardsPage',
      [routes.oldMemberHomeBoards.pattern]: 'memberHomeBoardsPage',
      [routes.teamHighlights.pattern]: 'memberTeamHighlightsPage',
      [routes.oldTeamHighlights.pattern]: 'memberTeamHighlightsPage',
      [routes.teamGettingStarted.pattern]: 'memberTeamGettingStartedPage',
      [routes.oldTeamGettingStarted.pattern]: 'memberTeamGettingStartedPage',
      [routes.teamReports.pattern]: 'teamReportsPage',
      [routes.oldTeamReports.pattern]: 'teamReportsPage',
      [routes.workspaceView.pattern]: 'workspaceViewPage',
      [routes.memberAllBoards.pattern]: 'memberAllBoardsPage',
      [routes.oldMemberAllBoards.pattern]: 'memberAllBoardsPage',
      [routes.memberCards.pattern]: 'memberCardsPage',
      [routes.oldMemberCards.pattern]: 'memberCardsPage',
      [routes.memberCardsForOrg.pattern]: 'memberCardsPage',
      [routes.oldMemberCardsForOrg.pattern]: 'memberCardsPage',
      [routes.memberActivity.pattern]: 'memberActivityPage',
      [routes.memberProfile.pattern]: 'memberProfilePage',
      [routes.oldMemberActivity.pattern]: 'memberActivityPage',
      [routes.memberTasks.pattern]: 'memberTasksPage',
      [routes.memberLabs.pattern]: 'memberLabsPage',
      [routes.oldMemberLabs.pattern]: 'memberLabsPage',
      [routes.oldOrganizationGuests.pattern]: 'organizationGuestsView',
      [routes.oldOrganizationRequests.pattern]: 'organizationRequestsView',
      [routes.oldOrganizationMembers.pattern]: 'organizationMembersView',
      [routes.organizationBoards.pattern]: 'organizationBoardsView',
      [routes.organizationById.pattern]: 'organizationById',
      [routes.organizationGuests.pattern]: 'organizationGuestsView',
      [routes.organizationRequests.pattern]: 'organizationRequestsView',
      [routes.organizationMembers.pattern]: 'organizationMembersView',
      [routes.organizationMemberCards.pattern]: 'organizationMemberCardsView',
      [routes.organizationExport.pattern]: 'organizationExportView',
      [routes.oldOrganizationExport.pattern]: 'organizationExportView',
      [routes.organizationPowerUps.pattern]: 'organizationPowerUpsPage',
      [routes.oldOrganizationPowerUps.pattern]: 'organizationPowerUpsPage',
      [routes.organizationTables.pattern]: 'organizationTableView',
      [routes.oldOrganizationTables.pattern]: 'organizationTableView',
      [routes.organizationFreeTrial.pattern]: 'freeTrialView',
      [routes.oldOrganizationFreeTrial.pattern]: 'freeTrialView',
      [routes.workspaceDefaultCustomTableView.pattern]:
        'workspaceDefaultCustomTableViewPage',
      [routes.oldWorkspaceDefaultCustomTableView.pattern]:
        'workspaceDefaultCustomTableViewPage',
      [routes.workspaceDefaultCustomCalendarView.pattern]:
        'workspaceDefaultCustomCalendarViewPage',
      [routes.oldWorkspaceDefaultCustomCalendarView.pattern]:
        'workspaceDefaultCustomCalendarViewPage',
      [routes.errorPage.pattern]: 'showErrorPage',
    };
  }

  start() {
    controllerEvents.on('clearPreviousView', (options) => {
      this.clearPreviousView(options);
    });
    controllerEvents.on('setViewType', (viewType) => {
      this.setViewType(viewType);
    });
    // Dependency required at call site to avoid import cycles, do not lift to top of module
    const { JoinOnConfirm } = require('app/scripts/lib/join-on-confirm');
    realtimeUpdaterEvents.on(
      'subscription_invalid',
      (function (_this) {
        return function (modelType, idModel) {
          const shouldDisplayError = function () {
            switch (modelType) {
              case 'Board':
                return currentModelManager.onBoardView(idModel);
              case 'Organization':
                return currentModelManager.onOrganizationView(idModel);
              case 'Enterprise':
                return currentModelManager.onEnterpriseView(idModel);
              default:
                return false;
            }
          }.call(_this);
          if (shouldDisplayError) {
            const errorType =
              modelType === 'Board' ? 'boardNotFound' : 'notFound';
            return errorPage({
              errorType,
            });
          }
        };
      })(this),
    );
    loadHeaderDataAndTriggerWaits(this, 'loadHeaderData', 'headerData');
    loadHeaderDataAndTriggerWaits(this, 'loadBoardsData', 'boardsData');
    if (isMemberLoggedIn()) {
      // @ts-expect-error TS(2531): Object is possibly 'null'.
      cacheFactory
        .waitForQueryHydratedTo('MemberHeader', 'ModelCache')
        .then(function () {
          const me = Auth.me();

          const premiumFeaturesSet = new Set<PremiumFeature>();
          const productSet = new Set<number>();

          me.organizationList.forEach((org) => {
            const product = org.getProduct();
            if (product) {
              productSet.add(product);
            }
            org
              .get('premiumFeatures')
              ?.forEach((feature) => premiumFeaturesSet.add(feature));
          });

          const userData = {
            clientVersion,
            emailDomain:
              dangerouslyConvertPrivacyString(me.get('email'))?.split('@')[1] ??
              '',
            hasBC: me.organizationList.some((org) => org.hasPaidProduct()),
            hasMultipleEmails: (me.get('logins') ?? []).length > 1,
            head: client.head,
            idEnterprises:
              me.get('enterprises')?.map((enterprise) => enterprise.id) ?? [],
            idOrgs: me.get('idOrganizations') ?? [],
            isClaimable: me.get('logins')?.some(
              (login) =>
                // @ts-expect-error TS(2339): Property 'claimable' does not exist on type 'Login... Remove this comment to see the full error message
                login.claimable,
            ),
            inEnterprise: (me.get('enterprises') || []).length > 0,
            orgs: ['[Redacted]'],
            // dates should be formatted as UNIX milliseconds
            signupDate: Util.idToDate(me.id).getTime(),
            premiumFeatures: Array.from(premiumFeaturesSet),
            products: Array.from(productSet),
            version: client.version,
          };

          // Update the user data in the dynamic config
          return dynamicConfigClient.refineUserData(userData);
        });
    } else {
      // Have the user object reflect a user that isn't logged in
      const userData = {
        clientVersion,
        emailDomain: '',
        hasBC: false,
        hasMultipleEmails: false,
        head: client.head,
        idEnterprises: [],
        idOrgs: [],
        inEnterprise: false,
        isClaimable: false,
        orgs: [],
        premiumFeatures: [],
        products: [],
        signupDate: undefined,
        version: client.version,
      };

      dynamicConfigClient.refineUserData(userData);
      cacheFactory.markQueryHydratedFor('MemberHeader', 'ModelCache');
      cacheFactory.markQueryHydratedFor('MemberBoards', 'ModelCache');
    }

    return JoinOnConfirm.autoJoin()
      .then(function (didJoin: boolean) {
        if (didJoin) {
          // We can't trust that the stuff that we quickloaded is
          // going to be accurate, or that we'll catch the updates
          // when we subscribe, so just dump it to be safe
          return QuickLoad.clear();
        }
      })
      .return();
  }

  viewType = 'none';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  topLevelView(viewType: any, model: any, options: any = {}) {
    // eslint-disable-next-line eqeqeq
    if (this.applicationView == null) {
      this.applicationView = new View();
    }
    return this.applicationView.subview(viewType, model, options);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  existingTopLevelView(viewType: any, model: any) {
    return this.applicationView.existingSubview(viewType, model);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  existingTopLevelViewOrUndefined(viewType: any, model: any) {
    return this.applicationView?.existingSubviewOrUndefined(viewType, model);
  }

  showingBoardOverlay() {
    return (
      showingPupDirectory() ||
      showingCalendar() ||
      showingMap() ||
      isShowingBoardViewSection('timeline') ||
      isShowingBoardViewSection('calendar-view') ||
      showingAutomaticReports()
    );
  }

  clearPreviousView(options?: { isNextViewReact?: boolean }) {
    // eslint-disable-next-line eqeqeq
    if (options == null) {
      options = {};
    }
    // eslint-disable-next-line eqeqeq
    if (options.isNextViewReact == null) {
      options.isNextViewReact = false;
    }
    const { isNextViewReact } = options;

    // Dependency required at call site to avoid import cycles, do not lift to top of module
    const { Dialog } = require('app/scripts/views/lib/Dialog');
    // Dependency required at call site to avoid import cycles, do not lift to top of module
    const { PluginModal } = require('app/scripts/views/lib/PluginModal');
    // Dependency required at call site to avoid import cycles, do not lift to top of module
    const { PopOver } = require('app/scripts/views/lib/PopOver');
    // Dependency required at call site to avoid import cycles, do not lift to top of module

    overlayState.setValue({
      overlayType: null,
      context: {},
    });

    const contentNode = document.getElementById('content');

    /**
     * This block manages the unmounting of components, addressing both modern React 18 (using React roots) and older React 17 style components.
     * It attempts to unmount the component using a project-specific `unmountReactRoot` method if available, exclusivley for React 18 components.
     * If `unmountReactRoot` is not used or doesn't exist, it falls back to a custom `unmountComponentAtNode` method intended for React 17 components.
     * If the custom `unmountComponentAtNode` fails (returns false), it uses ReactDOM's standard `unmountComponentAtNode` to ensure the component is fully unmounted.
     *
     * Important Note:
     * This unmounting strategy is specifically designed for this project's unique setup and should not be generalized to other projects without careful adaptation.
     * This approach utilizes project-specific implementations and should be phased out as the project transitions fully to React 18, at which point all `ReactDOM.render` usages should be eliminated.
     */
    // Attempt to unmount using the project-specific method for React 18 views
    if (this.unmountReactRoot) {
      this.unmountReactRoot();
      this.unmountReactRoot = null;
    } else if (contentNode) {
      const wasUnmountSuccessful = unmountComponentAtNode(contentNode);
      if (!wasUnmountSuccessful) {
        ReactDOM.unmountComponentAtNode(contentNode);
      }
    }

    // eslint-disable-next-line eqeqeq
    if (this.applicationView != null) {
      this.applicationView.remove();
    }
    delete this.applicationView;
    PopOver.hide();
    PluginModal.close();
    if (Dialog.isVisible) {
      Dialog.hide(true);
    }
    if (!isNextViewReact) {
      $('#content').html('');
    }
    // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    $('body').scrollTop('0');
    $('#trello-root')
      .removeClass('body-tabbed-page body-board-view')
      .removeClass(BOARD_VIEW_BACKGROUND_CLASSES)
      .css({
        'background-image': '',
        'background-color': '',
      });
    controllerEvents.trigger('clearAttachmentViewer');
  }

  // Call this *after* loading data, before displaying
  // (So the transition is instant)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setViewType(modelOrString: any) {
    // Dependency required at call site to avoid import cycles, do not lift to top of module
    const { Board } = require('app/scripts/models/Board');
    if (_.isString(modelOrString)) {
      currentModelManager.currentModel.set(null);
    } else {
      currentModelManager.currentModel.set(modelOrString);
    }
    this.currentPage = modelOrString;
    if (currentModelManager.currentModel.get() instanceof Board) {
      const board = currentModelManager.currentModel.get();
      // Setting this property on the controller before the board is marked as viewed below
      this.isFirstTimeViewingBoard = board.get('dateLastView') === null;
      this.waitForId(board, function () {
        // The right thing to do would be to have all this accounting-for-
        // views code observe the current location of the Controller, instead
        // of having the Controller know about that much. A good project
        // for a future refactor.
        // @ts-expect-error
        board.markAsViewed();

        // exclude inbox id into recent board global state
        const inboxIds = cachedInboxIdsState.value[Auth.me().id];
        if (inboxIds?.idBoard) {
          removeRecentBoardById(inboxIds.idBoard);
          boardsMenuState.setValue({
            ...boardsMenuState.value,
            idRecentBoards: [
              ...boardsMenuState.value.idRecentBoards.filter((idBoard) => {
                return idBoard !== inboxIds?.idBoard;
              }),
            ],
          });
        }

        if (inboxIds?.idBoard === board.id) {
          return;
        }

        pushRecentBoard({ id: board.id, dateLastView: new Date() });
        return boardsMenuState.setValue({
          ...boardsMenuState.value,
          idRecentBoards: [
            board.id,
            ...boardsMenuState.value.idRecentBoards.filter(
              (idBoard) => idBoard !== board.id,
            ),
          ].slice(0, 16),
        });
      });
    } else {
      FavIcon.setBackground({
        useDefault: true,
      });
    }
  }

  organizationById(idOrganization: string) {
    return ModelLoader.loadOrgNameById(idOrganization)
      .then((name: string) =>
        navigate(getOrganizationUrl(name), {
          trigger: true,
          replace: true,
        }),
      )
      .catch(ApiError, () => errorPage({}))
      .done();
  }

  quickBoard(search: string) {
    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "quick-board" */
            './quickBoard'
          ),
      ).then(({ QuickBoard }) => QuickBoard.quickBoard.call(this, search)),
    );
  }
  powerUpAdmin() {
    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "powerup-admin-page" */
            './powerupAdminPage'
          ),
      ).then(({ powerupAdminPage }) => powerupAdminPage()),
    );
  }
  editPowerUpPage() {
    renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "powerup-edit-powerup-page" */
            './powerupEditPowerUpPage'
          ),
      ).then(({ powerupEditPowerUpPage }) => powerupEditPowerUpPage()),
    );
  }
  publicDirectory() {
    renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "powerup-public-directory-page" */
            './powerupPublicDirectoryPage'
          ),
      ).then(({ powerupPublicDirectoryPage }) => powerupPublicDirectoryPage()),
    );
  }
  createFirstTeamPage() {
    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "create-first-team-page" */ './createFirstTeamPage'
          ),
      ).then(({ createFirstTeamPage }) => {
        return createFirstTeamPage();
      }),
    );
  }
  createWorkspacePage() {
    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "create-first-team-page" */ './createWorkspacePage'
          ),
      ).then(({ createWorkspacePage }) => {
        return createWorkspacePage();
      }),
    );
  }
  shortcutsPage() {
    return renderPage(
      importWithRetry(
        () =>
          import(/* webpackChunkName: "shortcuts-page" */ './shortcutsPage'),
      ).then(({ shortcutsPage }) => {
        return shortcutsPage.call(this);
      }),
    );
  }
  shortcutsOverlayPage() {
    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "shortcuts-overlay-page" */ './shortcutsOverlayPage'
          ),
      ).then(({ shortcutsOverlayPage }) => {
        return shortcutsOverlayPage();
      }),
    );
  }
  blankPage() {
    return renderPage(
      importWithRetry(
        () => import(/* webpackChunkName: "blank-page" */ './blankPage'),
      ).then(({ blankPage }) => {
        return blankPage();
      }),
    );
  }
  selectOrgToUpgradePage() {
    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "select-org-to-upgrade-page" */ './selectOrgToUpgradePage'
          ),
      ).then(({ selectOrgToUpgradePage }) => {
        return selectOrgToUpgradePage();
      }),
    );
  }
  selectTeamToUpgradePage() {
    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "select-team-to-upgrade-page" */ './selectTeamToUpgradePage'
          ),
      ).then(({ selectTeamToUpgradePage }) => {
        return selectTeamToUpgradePage();
      }),
    );
  }
  searchPage() {
    return renderPage(
      importWithRetry(
        () => import(/* webpackChunkName: "search-page" */ './searchPage'),
      ).then(({ searchPage }) => {
        return searchPage();
      }),
    );
  }
  openSourceAttributionsPage() {
    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "open-source-attributions-page" */ './openSourceAttributionsPage'
          ),
      ).then(({ openSourceAttributionsPage }) => {
        return openSourceAttributionsPage.call(this);
      }),
    );
  }
  templatesGalleryPublicPage() {
    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "templates-gallery-public-page" */ './templatesGalleryPublicPage'
          ),
      ).then(({ templatesGalleryPublicPage }) => {
        return templatesGalleryPublicPage.call(this);
      }),
    );
  }
  inviteAcceptBoardPage() {
    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "invite-accept-page" */
            './inviteAcceptPage'
          ),
      ).then(({ InviteAcceptPage }) =>
        InviteAcceptPage.boardInvitationPage.call(this),
      ),
    );
  }
  inviteAcceptTeamPage() {
    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "invite-accept-page" */
            './inviteAcceptPage'
          ),
      ).then(({ InviteAcceptPage }) =>
        InviteAcceptPage.teamInvitationPage.call(this),
      ),
    );
  }
  boardPage(
    shortLink: string,
    path?: string | null,
    referrerUsername?: string | null,
  ) {
    preloadCurrentBoardViewAssets();
    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "new-board-page" */
            './newBoardPage'
          ),
      ).then(({ newBoardPage }) => newBoardPage.call(this)),
    );
  }
  workspaceDefaultCustomTableViewPage(orgname: string) {
    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "workspace-view-page" */
            './workspaceViewPage'
          ),
      ).then(async ({ workspaceDefaultCustomTableViewPage }) => {
        // update URL to include `/w/` if it matches old pattern
        const { origin, pathname } = getLocation();
        const routeId = getRouteIdFromPathname(pathname);
        if (
          routeId === RouteId.OLD_WORKSPACE_DEFAULT_CUSTOM_TABLE_VIEW &&
          (await getFeatureGateAsync('legacy_url_error_page'))
        ) {
          return this.legacyUrlErrorPage(
            origin + getWorkspaceCustomTableViewUrl(orgname),
          );
        } else if (
          routeId === RouteId.OLD_WORKSPACE_DEFAULT_CUSTOM_TABLE_VIEW
        ) {
          return navigate(getWorkspaceCustomTableViewUrl(orgname), {
            replace: true,
            trigger: true,
          });
        }
        return workspaceDefaultCustomTableViewPage();
      }),
    );
  }
  workspaceDefaultCustomCalendarViewPage(orgname: string) {
    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "workspace-view-page" */
            './workspaceViewPage'
          ),
      ).then(async ({ workspaceDefaultCustomCalendarViewPage }) => {
        // update URL to include `/w/` if it matches old pattern
        const { origin, pathname } = getLocation();
        const routeId = getRouteIdFromPathname(pathname);
        if (
          routeId === RouteId.OLD_WORKSPACE_DEFAULT_CUSTOM_CALENDAR_VIEW &&
          (await getFeatureGateAsync('legacy_url_error_page'))
        ) {
          return this.legacyUrlErrorPage(
            origin + getWorkspaceDefaultCustomCalendarViewUrl(orgname),
          );
        } else if (
          routeId === RouteId.OLD_WORKSPACE_DEFAULT_CUSTOM_CALENDAR_VIEW
        ) {
          return navigate(getWorkspaceDefaultCustomCalendarViewUrl(orgname), {
            replace: true,
            trigger: true,
          });
        }
        return workspaceDefaultCustomCalendarViewPage();
      }),
    );
  }
  workspaceViewPage(shortLink: string) {
    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "workspace-view-page" */
            './workspaceViewPage'
          ),
      ).then(({ workspaceViewPage }) => workspaceViewPage(shortLink)),
    );
  }
  cardPage(id: string) {
    preloadCurrentBoardViewAssets();
    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "new-board-page" */
            './newBoardPage'
          ),
      ).then(({ newBoardPage }) => newBoardPage.call(this)),
    );
  }
  createFirstBoardPage() {
    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "create-first-board-page" */
            './createFirstBoardPage'
          ),
      ).then(({ createFirstBoardPage }) => createFirstBoardPage.call(this)),
    );
  }
  userOrOrgAccountPage(name: PIIString | string) {
    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "user-or-org-account-page" */ './userOrOrgAccountPage'
          ),
      ).then(async ({ userOrOrgAccountPage }) => {
        const { origin, pathname } = getLocation();
        const routeId = getRouteIdFromPathname(pathname);
        if (
          routeId === RouteId.OLD_ACCOUNT &&
          (await getFeatureGateAsync('legacy_url_error_page'))
        ) {
          return this.legacyUrlErrorPage(origin + getMemberAccountUrl(name));
        }
        return userOrOrgAccountPage.call(this, name);
      }),
    );
  }
  userOrOrgProfilePage(name: PIIString | string) {
    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "user-or-org-profile-page" */ './userOrOrgProfilePage'
          ),
      ).then(({ userOrOrgProfilePage }) => {
        return userOrOrgProfilePage.call(this, name);
      }),
    );
  }
  userOrOrgBillingPage(name: string) {
    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "user-or-org-billing-page" */ './userOrOrgBillingPage'
          ),
      ).then(({ userOrOrgBillingPage }) => {
        return userOrOrgBillingPage.call(this, name);
      }),
    );
  }
  enterpriseAdminDashboardView(name: string) {
    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "enterprise-admin-dashboard-page" */ './enterpriseAdminDashboardPage'
          ),
      ).then(({ enterpriseAdminDashboardPage }) => {
        // @ts-expect-error TS(2554): Expected 3 arguments, but got 2.
        return enterpriseAdminDashboardPage.call(this, name);
      }),
    );
  }
  enterpriseDashTab(name: string, tab: string | null) {
    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "enterprise-admin-dashboard-page" */ './enterpriseAdminDashboardPage'
          ),
      ).then(({ enterpriseAdminDashboardPage }) => {
        return enterpriseAdminDashboardPage.call(this, name, tab);
      }),
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  memberHomePage(...args: any[]) {
    return renderPage(
      importWithRetry(
        () =>
          import(/* webpackChunkName: "member-home-page" */ './memberHomePage'),
      ).then(({ memberHomePage }) => {
        return memberHomePage.call(this, ...args);
      }),
    );
  }
  memberHomeBoardsPage(orgname: string) {
    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "member-home-boards-page" */ './memberHomeBoardsPage'
          ),
      ).then(async ({ memberHomeBoardsPage }) => {
        const { origin, pathname } = getLocation();
        const routeId = getRouteIdFromPathname(pathname);
        if (
          routeId === RouteId.OLD_MEMBER_HOME_WORKSPACE_BOARDS &&
          (await getFeatureGateAsync('legacy_url_error_page'))
        ) {
          //:orgname/home
          return this.legacyUrlErrorPage(
            origin + getOrganizationHomeUrl(orgname),
          );
        }
        return memberHomeBoardsPage.call(this, orgname);
      }),
    );
  }
  memberTeamGettingStartedPage(orgname: string) {
    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "team-getting-started-page" */ './teamGettingStartedPage'
          ),
      ).then(async ({ teamGettingStartedPage }) => {
        // update URL to include `/w/` if it matches the old pattern
        const { origin, pathname } = getLocation();
        const routeId = getRouteIdFromPathname(pathname);
        if (
          routeId === RouteId.OLD_ORGANIZATION_GETTING_STARTED &&
          (await getFeatureGateAsync('legacy_url_error_page'))
        ) {
          return this.legacyUrlErrorPage(
            origin + getTeamOnboardingUrl(orgname),
          );
        } else if (routeId === RouteId.OLD_ORGANIZATION_GETTING_STARTED) {
          return navigate(getTeamOnboardingUrl(orgname), {
            replace: true,
            trigger: true,
          });
        }
        return teamGettingStartedPage(orgname);
      }),
    );
  }
  memberTeamHighlightsPage(orgname: string) {
    return renderPage(
      importWithRetry(
        () =>
          import(/* webpackChunkName: "member-home-page" */ './memberHomePage'),
      ).then(async ({ memberHomePage }) => {
        // update URL to include `/w/` if it matches old pattern
        const { origin, pathname } = getLocation();
        const routeId = getRouteIdFromPathname(pathname);
        if (
          routeId === RouteId.OLD_ORGANIZATION_HIGHLIGHTS &&
          (await getFeatureGateAsync('legacy_url_error_page'))
        ) {
          return this.legacyUrlErrorPage(
            origin + getMemberOrgUrl(orgname, false, false),
          );
        } else if (routeId === RouteId.OLD_ORGANIZATION_HIGHLIGHTS) {
          return navigate(getMemberOrgUrl(orgname, false, false), {
            replace: true,
            trigger: true,
          });
        }

        const opts = {
          orgname,
          showHomeBoardsTab: false,
          showGettingStarted: false,
        };
        return memberHomePage.call(this, opts);
      }),
    );
  }
  teamReportsPage(orgname: string) {
    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "team-reports-page" */ './teamReportsPage'
          ),
      ).then(async ({ teamReportsPage }) => {
        const { origin, pathname } = getLocation();
        const routeId = getRouteIdFromPathname(pathname);
        if (
          routeId === RouteId.OLD_ORGANIZATION_REPORTS &&
          (await getFeatureGateAsync('legacy_url_error_page'))
        ) {
          return this.legacyUrlErrorPage(
            origin + getOrganizationReportsUrl(orgname),
          );
        } else if (routeId === RouteId.OLD_ORGANIZATION_REPORTS) {
          return navigate(getOrganizationReportsUrl(orgname), {
            replace: true,
            trigger: true,
          });
        }

        return teamReportsPage.call(this, orgname);
      }),
    );
  }
  memberAllBoardsPage(username: PIIString) {
    return renderPage(
      importWithRetry(
        () =>
          import(/* webpackChunkName: "member-home-page" */ './memberHomePage'),
      ).then(async ({ memberHomePage }) => {
        // caught by '/u/:username/boards' route
        // but we actually want to show the 'memberHomePage'
        // just without any organisation, to show the SH all boards tab

        // update URL to include `/u/` if it matches old pattern
        const { origin, pathname } = getLocation();
        const routeId = getRouteIdFromPathname(pathname);
        if (
          routeId === RouteId.OLD_MEMBER_ALL_BOARDS &&
          (await getFeatureGateAsync('legacy_url_error_page'))
        ) {
          return this.legacyUrlErrorPage(origin + getMemberBoardsUrl(username));
        } else if (routeId === RouteId.OLD_MEMBER_ALL_BOARDS) {
          return navigate(getMemberBoardsUrl(username), {
            replace: true,
            trigger: true,
          });
        }
        const opts = {
          orgname: null,
          showHomeBoardsTab: true,
          showGettingStarted: false,
        };
        return memberHomePage.call(this, opts);
      }),
    );
  }
  async memberProfilePage(
    username: PIIString,
    section?: string | null,
    subsection?: string | null,
  ) {
    const { pathname } = getLocation();
    const routeId = getRouteIdFromPathname(pathname);
    if (routeId === RouteId.MEMBER_LABS) {
      return this.memberLabsPage(username);
    }

    const isModernizationEnabled = await getFeatureGateAsync(
      'billplat_modernize_members_profile_page',
    );
    if (isModernizationEnabled) {
      // TODO: Implement modernized member profile page
      return renderPage(
        importWithRetry(
          () =>
            import(
              /* webpackChunkName: "member-profile-page-controller" */ './memberProfilePageController'
            ),
        ).then(({ memberProfilePageController }) =>
          memberProfilePageController({
            username,
            section,
            subsection,
          }),
        ),
      );
    } else {
      return renderPage(
        importWithRetry(
          () =>
            import(
              /* webpackChunkName: "member-profile-page" */ './memberProfilePage'
            ),
        ).then(({ memberProfilePage }) => {
          return memberProfilePage.call(this, username, section, subsection);
        }),
      );
    }
  }
  memberCardsPage(username = convertToPIIString('me'), orgname?: string) {
    return renderPage(
      importWithRetry(
        () => import(/* webpackChunkName: "member-page" */ './memberPage'),
      ).then(async ({ memberPage }) => {
        //matches routes.oldMemberCards (:username/cards) OR routes.oldMemberCardsForOrg (:username/cards/:orgname)
        const { origin, pathname } = getLocation();
        const routeId = getRouteIdFromPathname(pathname);
        if (
          (routeId === RouteId.OLD_MEMBER_CARDS ||
            routeId === RouteId.OLD_MEMBER_CARDS_FOR_ORG) &&
          (await getFeatureGateAsync('legacy_url_error_page'))
        ) {
          return this.legacyUrlErrorPage(
            origin + getMemberCardsUrl(username, orgname),
          );
        }
        return memberPage.call(this, {
          username,
          loadFn: 'loadMemberCardsData',
          viewClassName: 'MemberCardsView',
          getLocation: (_username) => {
            return getMemberCardsUrl(_username as string, orgname);
          },
          titleKey: 'cards',
          orgname,
        });
      }),
    );
  }
  async memberActivityPage(username = convertToPIIString('me')) {
    const { origin, pathname } = getLocation();
    const routeId = getRouteIdFromPathname(pathname);
    if (
      routeId === RouteId.OLD_MEMBER_ACTIVITY &&
      (await getFeatureGateAsync('legacy_url_error_page'))
    ) {
      //':username/activity'
      return this.legacyUrlErrorPage(origin + getMemberActivityUrl(username));
    }

    const isModernizationEnabled = await getFeatureGateAsync(
      'billplat_modernize_members_profile_page',
    );
    if (isModernizationEnabled) {
      return renderPage(
        importWithRetry(
          () =>
            import(
              /* webpackChunkName: "member-activity-page-controller" */ './memberActivityPageController'
            ),
        ).then(({ memberActivityPageController }) =>
          memberActivityPageController({
            username,
            section: 'activity',
            subsection: undefined,
          }),
        ),
      );
    }

    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "member-profile-page" */ './memberProfilePage'
          ),
      ).then(({ memberProfilePage }) => {
        return memberProfilePage.call(this, username, 'activity', undefined);
      }),
    );
  }
  legacyUrlErrorPage(correctUrl: string) {
    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "legacy-url-error-page" */ './legacyUrlErrorPage'
          ),
      ).then(({ legacyUrlErrorPage }) => {
        return legacyUrlErrorPage({ correctUrl });
      }),
    );
  }
  memberTasksPage() {
    TrelloStorage.set(getHomeLastTabStorageKey(), '/'); //#Trick Sticky Tabs into just redirecting us to Home
    return navigate('/', { replace: true, trigger: true });
  }
  memberLabsPage(username: PIIString) {
    return renderPage(
      importWithRetry(
        () =>
          import(/* webpackChunkName: "member-labs-page" */ './memberLabsPage'),
      ).then(async ({ memberLabsPage }) => {
        const { origin, pathname } = getLocation();
        const routeId = getRouteIdFromPathname(pathname);
        if (
          routeId === RouteId.OLD_MEMBER_LABS &&
          (await getFeatureGateAsync('legacy_url_error_page'))
        ) {
          // :username/labs
          return this.legacyUrlErrorPage(origin + getMemberLabsUrl(username));
        }
        return memberLabsPage();
      }),
    );
  }
  async organizationGuestsView(orgname: string) {
    // update URL to include `/w/` if it matches old pattern
    const { origin, pathname } = getLocation();
    const routeId = getRouteIdFromPathname(pathname);
    if (
      routeId === RouteId.OLD_ORGANIZATION_GUESTS &&
      (await getFeatureGateAsync('legacy_url_error_page'))
    ) {
      return this.legacyUrlErrorPage(origin + getOrganizationGuestUrl(orgname));
    } else if (routeId === RouteId.OLD_ORGANIZATION_GUESTS) {
      return navigate(getOrganizationGuestUrl(orgname), {
        replace: true,
        trigger: true,
      });
    }

    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "organization-members-page" */ './organizationMembersPage'
          ),
      ).then(({ organizationMembersPage }) =>
        organizationMembersPage({
          orgNameOrId: orgname,
        }),
      ),
    );
  }
  async organizationRequestsView(orgname: string) {
    // update URL to include `/w/` if it matches old pattern
    const { origin, pathname } = getLocation();
    const routeId = getRouteIdFromPathname(pathname);
    if (
      routeId === RouteId.OLD_ORGANIZATION_REQUESTS &&
      (await getFeatureGateAsync('legacy_url_error_page'))
    ) {
      return this.legacyUrlErrorPage(
        origin + getOrganizationRequestUrl(orgname),
      );
    } else if (routeId === RouteId.OLD_ORGANIZATION_REQUESTS) {
      return navigate(getOrganizationRequestUrl(orgname), {
        replace: true,
        trigger: true,
      });
    }

    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "organization-members-page" */ './organizationMembersPage'
          ),
      ).then(({ organizationMembersPage }) =>
        organizationMembersPage({
          orgNameOrId: orgname,
        }),
      ),
    );
  }
  async organizationMembersView(name: string) {
    // update URL to include `/w/` if it matches old pattern
    const { origin, pathname } = getLocation();
    const routeId = getRouteIdFromPathname(pathname);
    const orgName = name;
    if (
      routeId === RouteId.OLD_ORGANIZATION_MEMBERS &&
      (await getFeatureGateAsync('legacy_url_error_page'))
    ) {
      return this.legacyUrlErrorPage(
        origin + getOrganizationMembersUrl(orgName),
      );
    } else if (routeId === RouteId.OLD_ORGANIZATION_MEMBERS) {
      return navigate(getOrganizationMembersUrl(orgName), {
        replace: true,
        trigger: true,
      });
    }

    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "organization-members-page" */ './organizationMembersPage'
          ),
      ).then(({ organizationMembersPage }) =>
        organizationMembersPage({
          orgNameOrId: orgName,
        }),
      ),
    );
  }
  organizationMemberCardsView(name: string, username: PIIString) {
    // This now lives on the member cards page
    return navigate(getOrganizationMemberCardsUrl(name, username), {
      trigger: true,
    });
  }
  async organizationExportView(orgname: string) {
    // update URL to include `/w/` if it matches old pattern
    const { origin, pathname } = getLocation();
    const routeId = getRouteIdFromPathname(pathname);
    if (
      routeId === RouteId.OLD_ORGANIZATION_EXPORT &&
      (await getFeatureGateAsync('legacy_url_error_page'))
    ) {
      return this.legacyUrlErrorPage(
        origin + getOrganizationExportUrl(orgname),
      );
    } else if (routeId === RouteId.OLD_ORGANIZATION_EXPORT) {
      return navigate(getOrganizationExportUrl(orgname), {
        replace: true,
        trigger: true,
      });
    }

    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "organization-export-page" */ './organizationExportPage'
          ),
      ).then(({ organizationExportPage }) =>
        organizationExportPage({ orgNameOrId: orgname }),
      ),
    );
  }
  async organizationPowerUpsPage(orgname: string) {
    // update URL to include `/w/` if it matches old pattern
    const { origin, pathname } = getLocation();
    const routeId = getRouteIdFromPathname(pathname);
    if (
      routeId === RouteId.OLD_ORGANIZATION_POWER_UPS &&
      (await getFeatureGateAsync('legacy_url_error_page'))
    ) {
      return this.legacyUrlErrorPage(
        origin + getOrganizationPowerUpsUrl(orgname),
      );
    } else if (routeId === RouteId.OLD_ORGANIZATION_POWER_UPS) {
      return navigate(getOrganizationPowerUpsUrl(orgname), {
        replace: true,
        trigger: true,
      });
    }

    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "organization-powerups-page" */ './organizationPowerUpsPage'
          ),
      ).then(({ organizationPowerUpsPage }) =>
        organizationPowerUpsPage({
          orgNameOrId: orgname,
        }),
      ),
    );
  }
  // Routes to the fullscreen /w/views/table route. We've moved this from
  // /w/:team/tables to /w/:team/views/table.
  // This redirect will append ?populate= unless there are already params in the
  // url
  async organizationTableView(orgname: string) {
    const existingParams =
      window.location.search === '' ? undefined : window.location.search;

    const { origin, pathname } = getLocation();
    const routeId = getRouteIdFromPathname(pathname);
    if (
      routeId === RouteId.OLD_ORGANIZATION_TABLES &&
      (await getFeatureGateAsync('legacy_url_error_page'))
    ) {
      // w/:name/tables(?*query)
      return this.legacyUrlErrorPage(
        origin + getWorkspaceCustomTableViewUrl(orgname, existingParams),
      );
    }

    return navigate(getWorkspaceCustomTableViewUrl(orgname, existingParams), {
      trigger: true,
    });
  }

  async freeTrialView(orgname: string) {
    const { origin, pathname } = getLocation();
    const routeId = getRouteIdFromPathname(pathname);
    if (
      routeId === RouteId.OLD_ORGANIZATION_FREE_TRIAL &&
      (await getFeatureGateAsync('legacy_url_error_page'))
    ) {
      return this.legacyUrlErrorPage(
        origin + getOrganizationFreeTrialUrl(orgname),
      );
    } else if (routeId === RouteId.OLD_ORGANIZATION_FREE_TRIAL) {
      return navigate(getOrganizationFreeTrialUrl(orgname), {
        replace: true,
        trigger: true,
      });
    }

    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "organization-boards-page" */ './organizationBoardsPage'
          ),
      ).then(async ({ organizationBoardsPage }) =>
        organizationBoardsPage({ orgNameOrId: orgname }),
      ),
    );
  }
  // methods from organization-routes
  async organizationAccountView(orgname: string) {
    // update URL to include '/w/' if it matches old pattern
    const { pathname } = getLocation();
    const routeId = getRouteIdFromPathname(pathname);
    if (
      routeId === RouteId.OLD_ACCOUNT &&
      (await getFeatureGateAsync('legacy_url_error_page'))
    ) {
      return this.legacyUrlErrorPage(
        origin + getOrganizationAccountUrl(orgname),
      );
    } else if (routeId === RouteId.OLD_ACCOUNT) {
      return navigate(getOrganizationAccountUrl(orgname), {
        replace: true,
        trigger: true,
      });
    }

    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "organization-settings-page" */ './organizationSettingsPage'
          ),
      ).then(({ organizationSettingsPage }) =>
        organizationSettingsPage({
          orgNameOrId: orgname,
        }),
      ),
    );
  }
  async organizationBillingView(orgname: string, options = {}) {
    // update URL to include `/w/` if it matches old pattern
    const { pathname, search } = getLocation();
    const routeId = getRouteIdFromPathname(pathname);
    if (routeId === RouteId.BILLING) {
      return navigate(`${getOrganizationBillingUrl(orgname)}${search}`, {
        replace: true,
        trigger: true,
      });
    }

    if (!isMemberLoggedIn()) {
      window.location.assign(
        `/login?returnUrl=${encodeURIComponent(
          window.location.pathname + window.location.search,
        )}`,
      );
      return;
    }

    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "organization-billing-page" */ './organizationBillingPage'
          ),
      ).then(({ organizationBillingPage }) =>
        organizationBillingPage({ orgNameOrId: orgname }),
      ),
    );
  }

  async organizationBoardsView(orgname: string) {
    return renderPage(
      importWithRetry(
        () =>
          import(
            /* webpackChunkName: "organization-boards-page" */ './organizationBoardsPage'
          ),
      ).then(({ organizationBoardsPage }) =>
        organizationBoardsPage({ orgNameOrId: orgname }),
      ),
    );
  }

  showFatalErrorPage({
    errorType,
    error,
  }: {
    errorType: 'serverError';
    error: Error;
  }) {
    fatalErrorPage({
      errorType,
      error,
    });
  }
  showErrorPage({ errorType, reason }: ErrorProps) {
    errorPage({
      errorType,
      reason,
    });
  }
  redeemPage() {
    if (!isMemberLoggedIn()) {
      window.location.assign(
        `/login?returnUrl=${encodeURIComponent(
          window.location.pathname + window.location.search,
        )}`,
      );
      return;
    }

    return renderPage(
      importWithRetry(
        () => import(/* webpackChunkName: "redeem-page" */ './redeemPage'),
      ).then(({ redeemPage }) => redeemPage()),
    );
  }
}

// eslint-disable-next-line @trello/no-module-logic
const controller = new Controller();

export { controller as Controller };
