import { useCallback, useEffect } from 'react';

import { isMemberLoggedIn } from '@trello/authentication';
import { isEmbeddedDocument } from '@trello/browser';
import { useFeatureGate } from '@trello/feature-gate-client';
import type { RouteIdType } from '@trello/router';
import { RouteId, useIsActiveRoute } from '@trello/router';
import { useSharedState, useSharedStateSelector } from '@trello/shared-state';
import { workspaceNavigationHiddenState } from '@trello/workspace-navigation';
import { workspaceState } from '@trello/workspace-state';
import { usePreviousWhileLoading } from '@trello/workspaces';

import { workspaceNavigationErrorState } from './workspaceNavigationErrorState';

export const navHiddenRoutes = new Set<RouteIdType>([
  RouteId.MEMBER_HOME_WORKSPACE_BOARDS,
  RouteId.ORGANIZATION_HIGHLIGHTS,
  RouteId.OLD_ORGANIZATION_HIGHLIGHTS,
  RouteId.ORGANIZATION_REPORTS,
  RouteId.OLD_ORGANIZATION_REPORTS,
  RouteId.ORGANIZATION_GETTING_STARTED,
  RouteId.OLD_ORGANIZATION_GETTING_STARTED,
  RouteId.CREATE_FIRST_BOARD,
  RouteId.CREATE_FIRST_TEAM,
  RouteId.MEMBER_CARDS_FOR_ORG,
  RouteId.OLD_MEMBER_CARDS_FOR_ORG,
]);

export const fullScreenWorkspaceHiddenRoutes = new Set<RouteIdType>([
  RouteId.ORGANIZATION_BY_ID,
  RouteId.ORGANIZATION_BOARDS,
  RouteId.ORGANIZATION_MEMBERS,
  RouteId.ORGANIZATION_GUESTS,
  RouteId.ORGANIZATION_REQUESTS,
  RouteId.ACCOUNT,
  RouteId.ORGANIZATION_EXPORT,
  RouteId.ORGANIZATION_POWER_UPS,
  RouteId.WORKSPACE_BILLING,
]);

export const splitScreenHiddenRoutes = new Set<RouteIdType>([
  ...navHiddenRoutes,
  RouteId.CARD,
  RouteId.BOARD,
]);

export function useWorkspaceNavigationHiddenStateUpdater() {
  const [navHiddenState, setNavHiddenState] = useSharedState(
    workspaceNavigationHiddenState,
  );
  const { value: isSplitScreenEnabled } = useFeatureGate(
    'trello_personal_productivity_release',
  );

  const { value: isM2WorkspaceNavEnabled } = useFeatureGate(
    'billplat_m2_workspace_pages',
  );

  let blocklist = navHiddenRoutes;
  if (isSplitScreenEnabled) {
    blocklist = new Set([...blocklist, ...splitScreenHiddenRoutes]);
  }
  if (isM2WorkspaceNavEnabled) {
    blocklist = new Set([...blocklist, ...fullScreenWorkspaceHiddenRoutes]);
  }

  const [navErrorState] = useSharedState(workspaceNavigationErrorState);
  const isHiddenRoute = useIsActiveRoute(blocklist);

  const workspace = useSharedStateSelector(
    workspaceState,
    useCallback(
      (state) => ({
        isGlobal: state.isGlobal,
        isLoading: state.isLoading,
      }),
      [],
    ),
  );

  const isGlobal = usePreviousWhileLoading(
    workspace.isGlobal,
    workspace.isLoading,
    false,
  );

  const hideWorkspaceNav =
    isEmbeddedDocument() ||
    isHiddenRoute ||
    isGlobal ||
    navErrorState.hasError ||
    !isMemberLoggedIn();

  useEffect(() => {
    if (hideWorkspaceNav !== navHiddenState.hidden) {
      setNavHiddenState({ hidden: hideWorkspaceNav });
    }
  }, [hideWorkspaceNav, navHiddenState.hidden, setNavHiddenState]);
}
