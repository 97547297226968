import type { SafeUrl } from '@trello/safe-urls';

import { fetch } from './fetch';
import type { TrelloRequestInit } from './trelloFetch.types';

export const safeUrlFetchWrapper = (
  url: SafeUrl,
  init?: TrelloRequestInit,
): Promise<Response> => {
  // eslint-disable-next-line @trello/fetch-includes-client-version
  return fetch(url as unknown as string, init as RequestInit);
};
