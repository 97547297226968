import type { FunctionComponent } from 'react';

import { TrelloUserAri } from '@atlassian/ari';
import { useFeatureGate } from '@trello/feature-gate-client';

import { isMongoId } from 'app/scripts/init/useSocketSubscription';
import { useTrelloMemberUpdatedSubscription } from './TrelloMemberUpdatedSubscription.generated';

export interface TrelloMemberUpdatedSubscriptionProps {
  memberId?: string;
}

/**
 * Component that subscribes to the TrelloBoardUpdatedSubscription.
 * This is an optimization to avoid re-rendering more complex components
 * when subscription data arrives.
 * @param memberId id of the member in ARI format
 * @returns
 */
export const TrelloMemberUpdatedSubscription: FunctionComponent<
  TrelloMemberUpdatedSubscriptionProps
> = ({ memberId }) => {
  const useGqlSubscriptions = useFeatureGate('gql_client_subscriptions');

  const memberAri = isMongoId(memberId)
    ? TrelloUserAri.create({ userId: memberId }).toString()
    : memberId;

  // AGG Subscription needs a member ARI.  Skip until we have one
  useTrelloMemberUpdatedSubscription({
    variables: { memberId: memberAri || '' },
    // Turn off Apollo's default caching since we're handling that in cacheSubscriptionResponseLink
    fetchPolicy: 'no-cache',
    skip: !memberId || !useGqlSubscriptions,
  });

  return null;
};
