// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.d4y_pAKBJxRYjZ {
  display: flex;
  position: sticky;
  z-index: 3;
  top: 0;
  padding-bottom: 8px;
  background-color: var(--ds-surface-overlay, #ffffff);
}
.d4y_pAKBJxRYjZ .NwCwhEyzRSMeDK {
  position: absolute;
  top: 8px;
  left: 8px;
}
.d4y_pAKBJxRYjZ .jhR9pta4kgYESQ {
  flex: auto;
  margin: 0;
  padding-left: 32px;
}
.hFV1_RHYMpJBTW {
  padding-top: 8px;
}
.vP5u3NClGSbEvj {
  display: flex;
  box-sizing: border-box;
  height: 81px;
}
.vP5u3NClGSbEvj > li {
  display: inline-block;
}
.jobpuSsxR75JMo {
  box-sizing: content-box;
  width: calc(33.3% - 8px);
  height: 56px;
  padding: 4px;
}
.vP5u3NClGSbEvj .jobpuSsxR75JMo {
  box-sizing: border-box;
  flex: 1;
  width: auto;
  height: 81px;
}
.HlckXgKExBPyN9 {
  display: flex;
  align-items: center;
}
.IhkgFHwNSyxcUX {
  flex-grow: 1;
  margin: 0;
  font-size: 14px;
  line-height: 18px;
}
.fg054Pg0R38n2P {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  margin: 0;
  list-style: none;
}
.hy7T0kL4IW58ix {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 0;
  margin: 0;
  padding: 0;
  border-radius: 3px;
  background: none;
  background-color: var(--ds-background-neutral, #091e420f);
  background-position: center center;
  background-size: cover;
  box-shadow: none;
  color: var(--ds-background-inverse-subtle-pressed, #00000052);
  line-height: 0;
  cursor: pointer;
}
.hy7T0kL4IW58ix:focus,
.hy7T0kL4IW58ix:hover {
  background: none;
  background-color: var(--ds-surface, #ffffff);
  background-position: center center;
  background-size: cover;
  box-shadow: none;
}
.hy7T0kL4IW58ix:focus .EZIEn_g48s4bsD,
.hy7T0kL4IW58ix:hover .EZIEn_g48s4bsD {
  opacity: 1;
  background: var(--ds-background-inverse-subtle-hovered, #0000003d);
}
.hy7T0kL4IW58ix.bFAxrKmeXO4ul6::before,
.hy7T0kL4IW58ix:focus::before,
.hy7T0kL4IW58ix:hover::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.15);
}
.hy7T0kL4IW58ix:active::before {
  background: rgba(0, 0, 0, 0.25);
}
.hy7T0kL4IW58ix.bFAxrKmeXO4ul6::before {
  background-color: var(--ds-background-inverse-subtle-pressed, #00000052);
}
.hy7T0kL4IW58ix.bFAxrKmeXO4ul6:focus::before {
  background-color: var(--ds-background-inverse-subtle-pressed, #00000052);
}
.hy7T0kL4IW58ix.bFAxrKmeXO4ul6:hover::before,
.hy7T0kL4IW58ix.bFAxrKmeXO4ul6:active::before {
  background-color: var(--ds-background-inverse-subtle-pressed, #00000052);
}
.hy7T0kL4IW58ix > span {
  position: relative;
  z-index: 1;
}
.EZIEn_g48s4bsD {
  display: block;
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 20px;
  padding: 2px 4px;
  overflow: hidden;
  opacity: 0;
  color: var(--ds-text-inverse, #ffffff);
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  text-decoration: underline;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.EZIEn_g48s4bsD:hover {
  background: var(--ds-background-inverse-subtle-pressed, #00000052);
  color: var(--ds-text-inverse, #ffffff);
}
.EZIEn_g48s4bsD:focus {
  opacity: 1;
  background: var(--ds-background-inverse-subtle-pressed, #00000052);
}
.m7Hsf1zcPHaw9F {
  margin-bottom: 0;
  padding-bottom: 0;
}
.ZjLPqeeQCfD6xl {
  min-height: 500px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchWrapper": `d4y_pAKBJxRYjZ`,
	"searchIcon": `NwCwhEyzRSMeDK`,
	"searchInput": `jhR9pta4kgYESQ`,
	"backgroundChooserColors": `hFV1_RHYMpJBTW`,
	"itemRow": `vP5u3NClGSbEvj`,
	"backgroundGridItem": `jobpuSsxR75JMo`,
	"backgroundChooserHeader": `HlckXgKExBPyN9`,
	"backgroundChooserHeading": `IhkgFHwNSyxcUX`,
	"backgroundGrid": `fg054Pg0R38n2P`,
	"backgroundGridTrigger": `hy7T0kL4IW58ix`,
	"photoAttributionLink": `EZIEn_g48s4bsD`,
	"selectedPhoto": `bFAxrKmeXO4ul6`,
	"bottomPadding": `m7Hsf1zcPHaw9F`,
	"photosWrapper": `ZjLPqeeQCfD6xl`
};
export default ___CSS_LOADER_EXPORT___;
