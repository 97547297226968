import * as Types from '@trello/graphql/generated';

import DocumentNode from '@trello/graphql/src/documentNode';
import * as Apollo from '@apollo/client';
import process from 'process';
export type MemberEnterpriseDataFragment = (
  { __typename: 'Member' }
  & Pick<Types.Member, 'id' | 'idEnterprise'>
  & { enterprises: Array<(
    { __typename: 'Enterprise' }
    & Pick<Types.Enterprise, 'id'>
  )> }
);

export const MemberEnterpriseDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MemberEnterpriseData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Member' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enterprises' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ListValue',
                  values: [
                    { kind: 'EnumValue', value: 'saml' },
                    { kind: 'EnumValue', value: 'member' },
                    { kind: 'EnumValue', value: 'memberUnconfirmed' },
                    { kind: 'EnumValue', value: 'owned' },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'idEnterprise' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

interface UseMemberEnterpriseDataFragmentOptions
  extends Omit<
    Apollo.UseFragmentOptions<
      MemberEnterpriseDataFragment,
      Apollo.OperationVariables
    >,
    'fragment' | 'fragmentName' | 'from'
  > {
  from: Apollo.StoreObject | Apollo.Reference;
  returnPartialData?: boolean;
}

interface UseMemberEnterpriseDataFragmentResult
  extends Omit<Apollo.UseFragmentResult<MemberEnterpriseDataFragment>, 'data'> {
  data?: MemberEnterpriseDataFragment;
}

export const useMemberEnterpriseDataFragment = ({
  from,
  returnPartialData,
  ...options
}: UseMemberEnterpriseDataFragmentOptions): UseMemberEnterpriseDataFragmentResult => {
  const result = Apollo.useFragment<MemberEnterpriseDataFragment>({
    ...options,
    fragment: MemberEnterpriseDataFragmentDoc,
    fragmentName: 'MemberEnterpriseData',
    from: {
      __typename: 'Member',
      ...from,
    },
  });

  // Ensure that the fragment result is not typed as a DeepPartial.
  if (!result.complete && !returnPartialData) {
    if (process.env.NODE_ENV === 'development') {
      console.warn('Fragment data is incomplete.', result);
    }
    return {
      ...result,
      data: undefined,
    };
  }

  return { ...result, data: result.data as MemberEnterpriseDataFragment };
};
