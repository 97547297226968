import type { FunctionComponent } from 'react';
import { Suspense } from 'react';

import {
  ChunkLoadErrorBoundary,
  ErrorBoundary,
} from '@trello/error-boundaries';
import { useFeatureGate } from '@trello/feature-gate-client';
import { useLazyComponent } from '@trello/use-lazy-component';

export const LazyBoardSwitcher: FunctionComponent = () => {
  const BoardSwitcherDialog = useLazyComponent(
    () =>
      import(/* webpackChunkName: "board-switcher" */ './BoardSwitcherDialog'),
    { namedImport: 'BoardSwitcherDialog' },
  );

  const { value: isBoardSwitcherEnabled } = useFeatureGate(
    'trello_personal_productivity_release',
  );

  if (!isBoardSwitcherEnabled) {
    return null;
  }

  return (
    <ErrorBoundary
      tags={{
        ownershipArea: 'trello-web-eng',
        feature: 'Board Switcher',
      }}
    >
      <ChunkLoadErrorBoundary fallback={null}>
        <Suspense fallback={null}>
          <BoardSwitcherDialog />
        </Suspense>
      </ChunkLoadErrorBoundary>
    </ErrorBoundary>
  );
};
