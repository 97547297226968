import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import type { ConnectionAckMessage } from 'graphql-ws';
import { createClient } from 'graphql-ws';

import { getApiGatewayUrl } from '@trello/api-gateway';
import { Analytics } from '@trello/atlassian-analytics';
import { browserStr, osStr } from '@trello/browser';
import {
  dangerouslyGetFeatureGateSync,
  getFeatureGateAsync,
} from '@trello/feature-gate-client';
import {
  internetConnectionState,
  verifyAndUpdateInternetHealthUntilHealthy,
} from '@trello/internet-connection-state';
import { monitor, monitorStatus } from '@trello/monitor';
import {
  calculateBackOffRange,
  getBackOffDelay,
} from '@trello/reconnect-back-off';
import {
  logConnectionInformation,
  safelyUpdateGraphqlWebsocketState,
} from '@trello/web-sockets';

const SUBSCRIPTIONS_GATE_NAME = 'gql_client_subscriptions';

let enforcedDelayRange: [number, number] | null = null;

const getDelayRangeFromGraphqlWebsocketState = async (retries: number) => {
  const lessAggressiveReconnect = await getFeatureGateAsync(
    'goo_slower_client_reconnects',
  );

  /**
   * We're overriding the status multipliers here to make the reconnection
   * back-off less aggressive.  For the 2AF we need to reduce client
   * reconnect load on graphql-subscriptions.
   *
   * We need to use a feature gate here to add a stop gap measure to reduce
   * reconnect load. The initial attempt at this created issues with
   * feature gates in the members page if the feature gate storage was deleted.
   *
   * The default status multipliers are: { active: 1, idle: 10 }.
   */

  const activeMultiplier = lessAggressiveReconnect ? 5 : 1;

  const status = monitor.getHidden() ? 'idle' : monitor.getStatus();
  const retryDelayRange = calculateBackOffRange({
    status,
    attemptsCount: retries,

    statusMultipliersOverride: {
      active: activeMultiplier,
      idle: 10,
    },
  });

  return retryDelayRange;
};

export const retryWait = async (retries: number) => {
  const retryDelayRange =
    enforcedDelayRange ??
    (await getDelayRangeFromGraphqlWebsocketState(retries));

  safelyUpdateGraphqlWebsocketState('waiting_to_reconnect');
  // This is the logic reused from legacy WebSocket with a check verifyAndUpdateInternetHealthUntilHealthy
  // If the internet connection is healthy then we can retry, otherwise we do not need to retry
  // We subtract the time spent waiting to reconnect from the exponential backoff

  logConnectionInformation({
    source: 'graphqlWebsocketLink',
    eventName: 'Waiting until online',
  });
  const startedAt = Date.now();

  // Wait until the internet is healthy to try and connect
  await verifyAndUpdateInternetHealthUntilHealthy();
  logConnectionInformation({
    source: 'graphqlWebsocketLink',
    eventName: 'Done waiting until online',
  });
  // since we took some time to verify internet health, reduce the exponential backoff
  const msCheckingInternetHealth = Date.now() - startedAt;
  const retryDelay = getBackOffDelay({
    range: retryDelayRange,
    timeSpent: msCheckingInternetHealth,
  });

  logConnectionInformation({
    source: 'graphqlWebsocket',
    payload: {
      reconnectionAllowedIn: `${retryDelay}ms`,
      delayReason: 'Default delay',
      monitorStatus: monitor.getStatus(),
      monitorHidden: monitor.getHidden(),
      retryDelayRange,
    },
    eventName: 'GraphQL WebSocket closed',
  });

  let monitorUnsubscribe: (() => void) | undefined;
  // Wait the remaining delay. If we waited to reconnect longer than the original exponential backoff
  // then we will retry immediately.
  await new Promise((resolve) => {
    let timeoutId = setTimeout(resolve, retryDelay);

    monitorUnsubscribe = monitorStatus.subscribe(
      (status) => {
        const newRetryDelayRange =
          enforcedDelayRange ??
          calculateBackOffRange({
            status,
            attemptsCount: retries,
          });
        const msAlreadyWaited = Date.now() - startedAt;
        const newRetryDelay = getBackOffDelay({
          range: newRetryDelayRange,
          timeSpent: msAlreadyWaited,
        });

        const actualReconnectionDelay = Math.max(
          newRetryDelay - msAlreadyWaited,
          0,
        );

        logConnectionInformation({
          source: 'graphqlWebsocket',
          payload: {
            reconnectionAllowedIn: `${actualReconnectionDelay}ms`,
            reconnectDelayRange: newRetryDelayRange,
          },
          eventName: 'Bringing forward reconnection (user active)',
        });

        // Clear old timeout and set a new one with the updated delay
        clearTimeout(timeoutId);
        timeoutId = setTimeout(resolve, actualReconnectionDelay);
      },
      { onlyUpdateIfChanged: true },
    );
  });
  monitorUnsubscribe?.();
};

export const shouldRetry = () => {
  return dangerouslyGetFeatureGateSync(SUBSCRIPTIONS_GATE_NAME);
};

const getCommonEventAttributes = () => ({
  monitorStatus: monitor.getStatus(),
  monitorHidden: monitor.getHidden() === true ? 'hidden' : 'visible',
  internetConnectionState: internetConnectionState.value,
  browser: browserStr,
  os: osStr,
});

/**
 * Modeled after getWebSocketUrl function in realtime-updater package
 */
const getSubscriptionUrl = () => {
  const protocol = document.location.protocol === 'http:' ? 'ws:' : 'wss:';
  return `${protocol}//${document.location.host}${getApiGatewayUrl(
    '/graphql/subscriptions',
  )}`;
};

export const createWsLink = () => {
  let traceId: string | null = null;
  const wsLink = new GraphQLWsLink(
    createClient({
      url: () => {
        if (!traceId) {
          if (dangerouslyGetFeatureGateSync(SUBSCRIPTIONS_GATE_NAME)) {
            traceId = Analytics.startTask({
              taskName: 'create-session/socket/graphql',
              source: 'wsLink',
            });

            return `${getSubscriptionUrl()}?x-b3-traceid=${traceId}&x-b3-spanid=${Analytics.get64BitSpanId()}`;
          }
        }

        return getSubscriptionUrl();
      },
      retryAttempts: 100,
      retryWait,
      shouldRetry,
      on: {
        connecting: () => {
          safelyUpdateGraphqlWebsocketState('connecting');
        },
        connected: (
          _socket: unknown,
          _payload: ConnectionAckMessage['payload'],
          wasRetry: boolean,
        ) => {
          safelyUpdateGraphqlWebsocketState('connected');
          enforcedDelayRange = null;
          if (traceId) {
            Analytics.taskSucceeded({
              taskName: 'create-session/socket/graphql',
              source: 'wsLink',
              traceId,
            });

            traceId = null;
          }

          Analytics.sendOperationalEvent({
            action: wasRetry ? 'reconnected' : 'connected',
            actionSubject: 'graphqlSocketConnection',
            source: 'network:socket',
            attributes: getCommonEventAttributes(),
          });
        },
        closed: (event) => {
          if (!(event instanceof CloseEvent)) {
            safelyUpdateGraphqlWebsocketState('closed');
            Analytics.sendOperationalEvent({
              action: 'closed',
              actionSubject: 'graphqlSocketConnection',
              source: 'network:socket',
              attributes: {
                code: 'unknown',
                reason: 'unknown',
                wasClean: false,
                ...getCommonEventAttributes(),
              },
            });
            return;
          }

          switch (event.code) {
            /**
             * Add in more close codes here as necessary
             */
            case 4429: {
              safelyUpdateGraphqlWebsocketState(
                'too_many_initialization_requests',
              );
              const minDelay = parseInt(event.reason, 10) ?? 10;
              enforcedDelayRange = [minDelay * 1000, minDelay * 1000 * 5];
              break;
            }

            default: {
              safelyUpdateGraphqlWebsocketState('closed');
            }
          }

          /**
           * We're temporarily sending an operational event to see if we're receiving
           * any close codes back from the graphql-subscriptions service.
           */
          Analytics.sendOperationalEvent({
            action: 'closed',
            actionSubject: 'graphqlSocketConnection',
            source: 'network:socket',
            attributes: {
              code: event.code,
              reason: event.reason,
              wasClean: event.wasClean,
              ...getCommonEventAttributes(),
            },
          });
        },
        error: () => {
          logConnectionInformation({
            source: 'graphqlWebsocketLink',
            eventName: 'graphql websocket errored',
          });
          safelyUpdateGraphqlWebsocketState('disconnected');
          if (traceId) {
            Analytics.taskFailed({
              taskName: 'create-session/socket/graphql',
              source: 'wsLink',
              traceId,
              error: new Error('Could not connect'),
            });

            traceId = null;
          }
        },
      },
    }),
  );

  /**
   * If the internet goes offline, then terminate the socket so that it reconnects.
   * This will end up waiting until we are online before reconnecting.
   */
  internetConnectionState.subscribe(
    (state) => {
      if (state === 'unhealthy') {
        wsLink.client.terminate();
      }
    },
    { onlyUpdateIfChanged: true },
  );

  return wsLink;
};

// eslint-disable-next-line @trello/no-module-logic
export const wsLink = createWsLink();
