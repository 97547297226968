// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pr1necVvi4UYAj {
  min-height: 48px;
  max-height: 48px;
  overflow: hidden;
}
@media print {
  .pr1necVvi4UYAj {
    /* stylelint-disable-next-line declaration-no-important */
    display: none !important;
  }
}
.bDQq0sxKOlHWye {
  min-height: 70px;
  max-height: 70px;
  overflow: hidden;
}
@media (min-width: 992px) {
  .bDQq0sxKOlHWye {
    min-height: 60px;
    max-height: 60px;
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `pr1necVvi4UYAj`,
	"loggedOutHeader": `bDQq0sxKOlHWye`
};
export default ___CSS_LOADER_EXPORT___;
