import { useSharedState } from '@trello/shared-state';

import type {
  FeatureExperimentKeys,
  FeatureExperimentPrimaryIdentifiers,
} from './data/featureGates';
import { featureExperiments } from './data/featureGates';
import { featureGateClientInitializationState } from './featureGateClientInitializationState';
import { useFeatureGate } from './useFeatureGate';

/**
 * Returns true if and only if the shared state data in the statsig client contains the primary
 * identifier of the statsig experiment, as stated in the experiment definition in featureGates.ts.
 *
 * This is to avoid firing exposures on experiments which use a primary identifier
 * (such as Trello workspace id) which is not reliably set in the statsig client at all times
 */
export const useHasPrimaryIdentifier = <T extends FeatureExperimentKeys>(
  experimentName: T,
): boolean => {
  const { value: isFeatureGateEnabled } = useFeatureGate(
    'trello_experiment_primary_identifier_enforcement',
  );

  const [featureGateClientInitState] = useSharedState(
    featureGateClientInitializationState,
  );

  // Defaulting to true keeps old functionality (ie new check always passes)
  if (!isFeatureGateEnabled) {
    return true;
  }
  switch (
    featureExperiments[experimentName]
      ?.primaryIdentifier as FeatureExperimentPrimaryIdentifiers
  ) {
    case 'atlassianAccountId': {
      return !!featureGateClientInitState.identifiers?.atlassianAccountId;
    }
    case 'analyticsAnonymousId': {
      return !!featureGateClientInitState.identifiers?.analyticsAnonymousId;
    }
    case 'trelloUserId': {
      return !!featureGateClientInitState.identifiers?.trelloUserId;
    }
    case 'trelloWorkspaceId': {
      return !!featureGateClientInitState.identifiers?.trelloWorkspaceId;
    }
    default: {
      return true;
    }
  }
};
