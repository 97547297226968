import { useEffect, useMemo, useRef, useState } from 'react';

import FeatureGates from '@atlaskit/feature-gate-js-client';
import type { SourceType } from '@trello/analytics-types';
import { Analytics } from '@trello/atlassian-analytics';
import { deepEqual } from '@trello/objects';
import { useSharedState } from '@trello/shared-state';

import {
  type ExperimentVariations,
  type FeatureExperimentKeys,
  type FeatureExperimentParameters,
} from './data/featureGates';
import { featureGateClientInitializationState } from './featureGateClientInitializationState';
import { useHasPrimaryIdentifier } from './useHasPrimaryIdentifier';

interface LayerOptions<T extends FeatureExperimentKeys> {
  isLayer: true;
  parameterName: FeatureExperimentParameters<T>;
}

export const useFireExposureEvent = <T extends FeatureExperimentKeys>({
  experimentName,
  value,
  fireExposureEvent,
  options,
}: {
  experimentName: T;
  value: ExperimentVariations<T, FeatureExperimentParameters<T>>;
  fireExposureEvent?: boolean;
  options?: LayerOptions<T>;
}) => {
  const [prevExposureFiredValue, setPrevExposureFiredValue] =
    useState<typeof value>('not-enrolled');

  const [featureGateClientInitState] = useSharedState(
    featureGateClientInitializationState,
  );

  const hasPrimaryIdentifier = useHasPrimaryIdentifier(experimentName);

  const currentInitArgs = useMemo(
    () => ({
      identifiers: featureGateClientInitState.identifiers,
      customAttributes: featureGateClientInitState.customAttributes,
    }),
    [
      featureGateClientInitState.customAttributes,
      featureGateClientInitState.identifiers,
    ],
  );
  const prevInitArgs = useRef<typeof currentInitArgs>({
    identifiers: undefined,
    customAttributes: undefined,
  });

  useEffect(() => {
    if (
      featureGateClientInitState.isInitialized &&
      fireExposureEvent &&
      (prevExposureFiredValue !== value ||
        !deepEqual(prevInitArgs.current, currentInitArgs))
    ) {
      prevInitArgs.current = currentInitArgs;
      if (!hasPrimaryIdentifier) {
        // ** Temporarily disabled while @mpittman investigates the type issue **
        // Analytics.sendOperationalEvent({
        //   action: 'cancelled',
        //   actionSubject: 'experiment exposure',
        //   source: experimentName,
        //   attributes: {
        //     hasPrimaryIdentifier,
        //     experimentName,
        //     primaryIdentifier:
        //       featureExperiments[experimentName].primaryIdentifier,
        //   },
        // });
      } else {
        if (options?.isLayer) {
          FeatureGates.manuallyLogLayerExposure(
            experimentName,
            options.parameterName as string,
          );
        } else {
          FeatureGates.manuallyLogExperimentExposure(experimentName);
        }
        Analytics.sendTrackEvent({
          action: 'exposed',
          actionSubject: 'feature',
          source: 'trello' as SourceType,
          attributes: {
            experimentName,
            value,
          },
        });
      }
      setPrevExposureFiredValue(value);
    }
  }, [
    currentInitArgs,
    experimentName,
    featureGateClientInitState.isInitialized,
    fireExposureEvent,
    hasPrimaryIdentifier,
    options?.isLayer,
    options?.parameterName,
    prevExposureFiredValue,
    value,
  ]);
};
