import type { FunctionComponent } from 'react';

import NewApplicationSwitcherIcon from '@atlaskit/icon/core/app-switcher';
import { useFeatureGate } from '@trello/feature-gate-client';
import { forTemplate } from '@trello/legacy-i18n';
import { ApplicationSwitcherIcon } from '@trello/nachos/icons/application-switcher';

const format = forTemplate('cross_flow');

interface Props {
  dangerous_className?: string;
}

export const AtlassianAppSwitcherIcon: FunctionComponent<Props> = ({
  dangerous_className,
}) => {
  const { value: isSplitScreenEnabled } = useFeatureGate(
    'trello_personal_productivity_release',
  );

  if (!isSplitScreenEnabled) {
    return (
      <ApplicationSwitcherIcon
        color="currentColor"
        label={format('switch to')}
        size="medium"
        dangerous_className={dangerous_className}
      />
    );
  }

  return (
    <span className={dangerous_className}>
      <NewApplicationSwitcherIcon
        color="currentColor"
        label={format('switch to')}
        spacing="spacious"
        testId="NewApplicationSwitcherIcon"
      />
    </span>
  );
};
